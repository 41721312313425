import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyCZvIemR4CjovmFu8vt07YBnTVG1wrJjkk',
  authDomain: 'tikafiskal.firebaseapp.com',
  databaseURL: 'https://tikafiskal.firebaseio.com',
  projectId: 'tikafiskal',
  storageBucket: 'tikafiskal.appspot.com',
  messagingSenderId: '583577279819',
  appId: '1:583577279819:web:790d02738255752771dcce',
};

firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const firestore = firebase.firestore();
