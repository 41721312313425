// Hook
const SERVER_IP = 'https://cis.tika-zlatnik.hr:3001';

async function getRequest(
  endpoint: string,
  body?: any,
  method?: 'GET' | 'POST'
) {
  const fetchOptions: any = {
    method: method || (body ? 'POST' : 'GET'),
    headers: { 'Content-Type': 'application/json' },
  };

  let url = `${SERVER_IP}/${endpoint}`;

  if (method === 'GET' && body) {
    url +=
      '?' +
      Object.keys(body)
        .filter((key) => body[key] !== undefined)
        .map((key) => `${key}=${body[key]}`)
        .join('&');
  } else if (body) {
    fetchOptions.body = JSON.stringify(body);
  }

  try {
    const anyResponse = await fetch(url, fetchOptions);

    const response = await anyResponse.json();

    return response;
  } catch (error) {
    console.error(error);
    return {};
  }
}

export async function getServerCompanyData(body?: any) {
  return getRequest('company', body, 'GET');
}

export async function getServerFiskalData(body?: any) {
  return getRequest('receipt', body, 'GET');
}

export async function getServerStatusData() {
  return getRequest('status', {}, 'GET');
}
