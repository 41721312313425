import React, { useState } from 'react';
import { auth } from '../services/firebase';
import {
  Paper,
  makeStyles,
  Grid,
  TextField,
  Button,
  Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Face, Fingerprint } from '@material-ui/icons';
import { RouteComponentProps } from '@reach/router';

const useStyles = makeStyles({
  margin: {
    margin: '8px',
  },
  padding: {
    padding: '4px',
  },
});

const SignIn = (_: RouteComponentProps) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const signInWithEmailAndPasswordHandler = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    console.log(email);
    auth.signInWithEmailAndPassword(email, password).catch((error) => {
      setError(error.message);
      console.error('Error signing in with password and email', error);
    });
  };

  const onChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { id, value } = event.currentTarget;

    if (id === 'username') {
      setEmail(value);
    } else if (id === 'password') {
      setPassword(value);
    }
  };
  const classes = useStyles();
  return (
    <Paper className={classes!.margin}>
      <div className={classes!.margin}>
        <Typography variant="h4" component="h1" gutterBottom>
          Prijavite se
        </Typography>
        <Grid container spacing={8} alignItems="flex-end">
          <Grid item>
            <Face />
          </Grid>
          <Grid item md={true} sm={true} xs={true}>
            <TextField
              id="username"
              label="Username"
              type="email"
              fullWidth
              autoFocus
              required
              onChange={onChangeHandler}
            />
          </Grid>
        </Grid>
        <Grid container spacing={8} alignItems="flex-end">
          <Grid item>
            <Fingerprint />
          </Grid>
          <Grid item md={true} sm={true} xs={true}>
            <TextField
              id="password"
              label="Password"
              type="password"
              fullWidth
              required
              onChange={onChangeHandler}
            />
          </Grid>
        </Grid>
        {/* <Grid container alignItems="center" justify="space-between">
          <Grid item>
            <FormControlLabel
              control={<Checkbox color="primary" />}
              label="Remember me"
            />
          </Grid>
          <Grid item>
            <Button
              disableFocusRipple
              disableRipple
              style={{ textTransform: 'none' }}
              variant="text"
              color="primary"
            >
              Forgot password ?
            </Button>
          </Grid>
        </Grid> */}
        <Grid
          container
          direction="column"
          alignItems="center"
          style={{ marginTop: '10px', paddingBottom: '10px' }}
        >
          <Button
            variant="outlined"
            color="primary"
            style={{
              textTransform: 'none',
              marginBottom: '10px',
              width: '80px',
            }}
            onClick={signInWithEmailAndPasswordHandler}
          >
            Prijava
          </Button>
          {error && <Alert severity="error">{error}</Alert>}
        </Grid>
      </div>
    </Paper>
  );
};
export default SignIn;
