import React, { useContext, useEffect, useCallback } from 'react';
import { Button, Link, ListSubheader, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { UserContext } from '../providers/UserProvider';
import {
  getServerCompanyData,
  getServerFiskalData,
  getServerStatusData,
} from '../services/api';
import { auth } from '../services/firebase';

// import EnhancedTable from './EnhancedTable';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import Modal from '@material-ui/core/Modal';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';

import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { DateRangePicker, isInclusivelyBeforeDay } from 'react-dates';
import moment from 'moment';
import { isMobile } from 'react-device-detect';

moment.locale('hr');

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: 8 * 3,
    overflowX: 'auto',
  },
  paginationContainer: {
    flexShrink: 0,
    marginLeft: '3px',
  },
  table: {
    minWidth: 300,
  },
  gridList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
    '&::-webkit-scrollbar': {
      // display: '',
    },
    minHeight: '200px',
  },
  red: {
    backgroundColor: '#FF00006F',
  },
  green: {
    backgroundColor: '#00FF006F',
  },
  formControl: {
    margin: 20,
    minWidth: 120,
    maxWidth: 300,
  },
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function TablePaginationActions(props: any) {
  const classes = useStyles();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event: any) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event: any) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event: any) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event: any) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.paginationContainer}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="prva stranica"
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="prethodna stranica"
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="sljedeća stranica"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="zadnja stranica"
      >
        <LastPageIcon />
      </IconButton>
    </div>
  );
}

const FiscalTable = () => {
  const user = useContext(UserContext);
  const classes = useStyles();

  const adminUser = user?.userDocument?.admin ?? false;
  const showDrink =
    user?.userDocument?.napitci ?? user?.userDocument?.showDrink ?? false;

  const [isModalOpen, toggleModal] = React.useState(false);
  const [modalStyle] = React.useState(getModalStyle);

  const [cancelReceipt, setCancelReceipt] = React.useState<any | undefined>(
    undefined
  );

  const handleOpenModal = (receipt: any) => () => {
    setCancelReceipt(receipt);
    toggleModal(true);
  };

  const handleCloseModal = () => {
    toggleModal(false);
    setCancelReceipt(undefined);
  };
  const [data, setData] = React.useState<any[] | undefined>(undefined);
  const [filteredData, setFilteredData] = React.useState<any[] | undefined>(
    undefined
  );
  const [status, setStatus] = React.useState<any[] | undefined>(undefined);
  const [lastRefresh, setLastRefresh] = React.useState<Date>(new Date());

  const [dates, setDates] = React.useState<{
    dateFrom: moment.Moment | null;
    dateTo: moment.Moment | null;
  }>({ dateFrom: moment(), dateTo: moment() });

  const [focusedInput, setFocusedInput] = React.useState<
    'startDate' | 'endDate' | null
  >(null);

  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [page, setPage] = React.useState(0);

  const [selectedIds, setSelectedIds] = React.useState<any[]>([]);
  // const [availableCompanies, setAvailableCompanies] = React.useState<any[]>([]);
  const [devices, setDevices] = React.useState<any[]>([]);
  // const [bussinessPlaces, setBussinessPlaces] = React.useState<any[]>([]);

  const handleSelectedIds = (event: React.ChangeEvent<any>) => {
    setPage(0);
    setSelectedIds(event.target.value);
  };

  const refreshStatus = useCallback(async () => {
    const statuses = await getServerStatusData();
    setStatus(statuses);
    setLastRefresh(new Date());
  }, []);

  const endpointURL =
    'https://cis.tika-zlatnik.hr:8443/tikaVendingREST/rest/fiskalsamoposluzni/';

  const createReport = (isDetailed?: boolean) => {
    const selectedDeviceIds = selectedIds;

    if (devices.length === 1) {
      selectedDeviceIds.push(devices[0].ID);
    }

    if (selectedDeviceIds.length === 0) {
      return;
    }

    const selectedBussinessPlaceIds = devices
      .filter((device) => selectedIds.includes(device.ID))
      .filter((device) => device.bussinessPlace)
      .map((device) => device.bussinessPlace.ID);

    if (selectedBussinessPlaceIds.length === 0) {
      return;
    }

    let ids = '';
    for (const id of selectedBussinessPlaceIds) {
      ids += `&ids=${id}`;
    }

    const dateFrom = (dates.dateFrom ?? dates.dateTo)?.format('yyyy-MM-DD');
    const dateTo = (dates.dateTo ?? dates.dateFrom)?.format('yyyy-MM-DD');

    if (!dateFrom || !dateTo) {
      return;
    }

    const url = `${endpointURL}${dateFrom}/${dateTo}?summary=${
      !!isDetailed ? 'false' : 'true'
    }${ids}`;
    window.open(url, '_blank');
  };

  const createDetailedReport = () => {
    createReport(true);
  };

  const createSummaryReport = () => {
    createReport(false);
  };

  const refreshData = useCallback(async () => {
    const companyID =
      user?.userDocument?.firmaId ?? user?.userDocument?.companyID ?? undefined;

    const deviceID = adminUser
      ? undefined
      : companyID
      ? undefined
      : user?.userDocument?.deviceID;

    if (!adminUser && !companyID && !deviceID) {
      return;
    }

    const fiskalData = await getServerFiskalData({
      companyID: adminUser ? undefined : companyID,
      deviceID,
      dateFrom: dates.dateFrom?.format('yyyy-MM-DD'),
      dateTo: dates.dateTo?.format('yyyy-MM-DD'),
      showCanceled: true,
    });

    setData(
      fiskalData && Array.isArray(fiskalData)
        ? fiskalData
            .map((single: any) => ({
              ...single,
              id: single.ID as number,
            }))
            .sort(
              (previous, current) =>
                new Date(previous.timeOfBilling).getTime() -
                new Date(current.timeOfBilling).getTime()
            )
        : []
    );
    setLastRefresh(new Date());
  }, [user, dates, adminUser]);

  const refreshCompany = useCallback(async () => {
    let companies = await getServerCompanyData();
    const userCompanyID =
      user?.userDocument?.firmaId ?? user?.userDocument?.companyID;
    const deviceID = adminUser
      ? undefined
      : userCompanyID
      ? undefined
      : user?.userDocument?.deviceID;

    if (!adminUser && !deviceID) {
      companies = companies.filter(
        (company: any) => company.ID === userCompanyID
      );
    }
    // setAvailableCompanies(companies);
    let availableDevices = companies
      .map((company: any) =>
        company.devices
          .filter((device: any) => device.active || adminUser)
          .map((device: any) => ({
            ...device,
            displayName:
              device.bussinessPlace && !device.test
                ? device.bussinessPlace.description ??
                  device.bussinessPlace.bussinessPlaceIdentifier
                : device.description,
          }))
          .sort((first: any, second: any) =>
            first.displayName.localeCompare(second.displayName)
          )
          .map((device: any, index: number) => ({
            ...device,
            companyName:
              index === 0 ? device.company?.name ?? 'NO-COMPANY' : undefined,
          }))
      )
      .flat();

    if (!userCompanyID && deviceID) {
      availableDevices = availableDevices.filter(
        (device: any) => device.ID === deviceID
      );
    }

    setDevices(availableDevices);
  }, [adminUser, user]);

  useEffect(() => {
    refreshData();
    if (adminUser) {
      refreshStatus();
      setInterval(refreshStatus, 1 * 60 * 1000);
    }
  }, [refreshData, refreshStatus, adminUser]);

  useEffect(() => {
    const selectedDevices = devices.filter((device) =>
      selectedIds.includes(device.ID)
    );

    const selectedBussinessPlaceIds = selectedDevices
      .filter((device) => device.bussinessPlace && !device.test)
      .map((device) => device.bussinessPlace.ID);

    const devicesWithoutBussinessPlace = selectedDevices
      .filter((device) => !device.bussinessPlace || device.test)
      .map((device) => device.ID);

    setFilteredData(
      (selectedBussinessPlaceIds?.length > 0 ||
        devicesWithoutBussinessPlace?.length > 0) &&
        data
        ? data.filter(
            (single) =>
              (single.bussinessPlace &&
                selectedBussinessPlaceIds?.includes(
                  single.bussinessPlace.ID
                )) ||
              devicesWithoutBussinessPlace?.includes(single.device.ID)
          )
        : data
    );
  }, [data, selectedIds, devices]);

  useEffect(() => {
    refreshCompany();
  }, [refreshCompany, user]);

  if (!user || !user.user || !user.userDocument) {
    return <Alert severity="error">Nemate pravo pristupa!</Alert>;
  }

  const pagedData = filteredData
    ? rowsPerPage > 0
      ? filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      : filteredData
    : [];

  const totalSum = filteredData
    ? filteredData
        .map((single) => single.totalAmount)
        .reduce((a: any, b: any) => a + b, 0)
    : 0;

  const totalSumTokens = filteredData
    ? filteredData
        .filter((single) => !single.totalAmount)
        .map(() => 5)
        .reduce((a: any, b: any) => a + b, 0)
    : 0;

  // const emptyRows =
  //   rowsPerPage -
  //   Math.min(rowsPerPage, (data?.length ?? 0) - page * rowsPerPage);

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // const deviceIds: { id: number; text: string }[] = [];
  // data &&
  //   data.forEach((single) => {
  //     if (!deviceIds.find((id) => single.device?.ID === id.id)) {
  //       deviceIds.push({
  //         id: single.device?.ID,
  //         text: single.device?.description,
  //       });
  //     }
  //   });

  // console.log(pagedData);

  return (
    <React.Fragment>
      <Typography variant="h4" component="h1" gutterBottom>
        Pozdrav{' '}
        {user && user.userDocument && user.userDocument.displayName
          ? user.userDocument.displayName
          : user && user.user
          ? user.user.email
          : ' korisnik'}
      </Typography>
      <DateRangePicker
        displayFormat={'DD/MM/yyyy'}
        isOutsideRange={(day) => !isInclusivelyBeforeDay(day, moment())}
        minimumNights={0}
        startDate={dates.dateFrom}
        startDateId="dateFrom"
        startDatePlaceholderText={'Početni datum'}
        endDate={dates.dateTo}
        endDateId="dateTo"
        endDatePlaceholderText={'Krajnji datum'}
        phrases={{ calendarLabel: 'Kalendar' }}
        firstDayOfWeek={1}
        onDatesChange={({ startDate, endDate }) =>
          setDates({ dateFrom: startDate, dateTo: endDate })
        }
        focusedInput={focusedInput}
        onFocusChange={setFocusedInput}
        orientation={isMobile ? 'vertical' : 'horizontal'}
        withFullScreenPortal={isMobile}
        readOnly={isMobile}
      />
      <Button
        variant="outlined"
        color="secondary"
        style={{
          textTransform: 'none',
          margin: `20px ${isMobile ? 0 : 20}px`,
          width: '286px',
          height: '48px',
        }}
        onClick={refreshData}
      >
        Osvježi
      </Button>

      <Button
        variant="outlined"
        color="secondary"
        style={{
          textTransform: 'none',
          margin: `20px ${isMobile ? 0 : 20}px`,
          width: '286px',
          height: '48px',
        }}
        onClick={createSummaryReport}
      >
        Izvještaj
      </Button>

      <Button
        variant="outlined"
        color="secondary"
        style={{
          textTransform: 'none',
          margin: `20px ${isMobile ? 0 : 20}px`,
          width: '286px',
          height: '48px',
        }}
        onClick={createDetailedReport}
      >
        Detaljan izvještaj
      </Button>
      {devices && devices.length > 1 && (
        <FormControl className={classes.formControl}>
          <InputLabel id="device-label" htmlFor="device-id">
            Uređaji
          </InputLabel>
          <Select
            labelId="device-label"
            id="device-id"
            multiple
            value={selectedIds}
            onChange={handleSelectedIds}
            input={<Input />}
            MenuProps={MenuProps}
          >
            {devices.map((device, i) =>
              device.companyName && adminUser ? (
                [
                  <ListSubheader
                    key={device.companyName}
                    value={device.companyName}
                  >
                    {device.companyName}
                  </ListSubheader>,
                  <MenuItem key={i} value={device.ID}>
                    {device.displayName}
                  </MenuItem>,
                ]
              ) : (
                <MenuItem key={i} value={device.ID}>
                  {device.displayName}
                </MenuItem>
              )
            )}
          </Select>
        </FormControl>
      )}
      {adminUser && status && (
        <React.Fragment>
          <Typography component="p" paragraph>
            Zadnje osvježavanje: {lastRefresh.toLocaleString()}
          </Typography>
          <GridList
            className={classes.gridList}
            cols={isMobile ? 1.1 : 2.5}
            style={{ height: '100%' }}
          >
            {status
              ?.map((status: any) => ({
                ...status,
                momentTime: moment(status.timeOfPing),
                diffHours: moment
                  .duration(moment().diff(moment(status.timeOfPing)))
                  .asHours(),
              }))
              .sort((a: any, b: any) => a.momentTime.diff(b.momentTime))
              .map((status: any, i) => (
                <GridListTile key={i} style={{ height: '100%' }}>
                  <Card
                    variant="elevation"
                    raised
                    className={
                      status.diffHours > 3 ? classes.red : classes.green
                    }
                    style={{ height: '100%' }}
                  >
                    <CardContent style={{ height: '100%' }}>
                      <Typography>Tvrtka: {status.companyName}</Typography>
                      <Typography>
                        Naziv uređaja: {status.description}
                      </Typography>
                      <Typography>Temperatura: {status.temperature}</Typography>
                      <Typography>
                        Signal:{' '}
                        {status.signalStrength
                          ? `${parseFloat(status.signalStrength).toFixed(2)}%`
                          : '<NEMA>'}
                      </Typography>
                      <Typography>
                        Zadnje paljenje:{' '}
                        {status.lastStartupDate
                          ? moment(status.lastStartupDate).format(
                              'HH:mm:ss (DD.MM.)'
                            )
                          : '<NEMA>'}
                      </Typography>
                      <Typography>
                        Zadnje gašenje:{' '}
                        {status.lastShutdownDate
                          ? moment(status.lastShutdownDate).format(
                              'HH:mm:ss (DD.MM.)'
                            )
                          : '<NEMA>'}
                      </Typography>
                      <Typography>
                        Broj javljanja: {status.numberOfStatusesToday}
                      </Typography>
                      <Typography>
                        Zadnje javljanje:{' '}
                        {moment(status.timeOfPing).format('HH:mm:ss (DD.MM.)')}
                      </Typography>
                    </CardContent>
                  </Card>
                </GridListTile>
              ))}
          </GridList>
        </React.Fragment>
      )}
      {/* <EnhancedTable
        columns={columns}
        data={data || []}
        setData={setData}
        updateMyData={updateMyData}
        skipPageReset={skipPageReset}
      /> */}
      <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Uređaj (id)</TableCell>
              <TableCell>Br.</TableCell>
              {showDrink && <TableCell>Piće</TableCell>}
              <TableCell align="right">Iznos (HRK)</TableCell>
              <TableCell>Vrijeme</TableCell>
              <TableCell>Porezna</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pagedData &&
              pagedData.map((single: any) => {
                return (
                  <TableRow key={single.ID}>
                    <TableCell component="th" scope="row">
                      {single.bussinessPlace?.description ??
                        single.bussinessPlace?.bussinessPlaceIdentifier ??
                        single.device?.description ??
                        single.device.ID}{' '}
                      {adminUser ? `(${single.device.ID})` : ''}
                    </TableCell>
                    <TableCell>
                      {single.noOfReceipt ?? ''}
                      {single.canceledReceipt || single.cancelReceipt ? (
                        <Button onClick={handleOpenModal(single)}>
                          {' '}
                          (STORNO br.
                          {
                            (single.canceledReceipt ?? single.cancelReceipt)
                              .noOfReceipt
                          }
                          )
                        </Button>
                      ) : (
                        ''
                      )}
                    </TableCell>
                    {showDrink && (
                      <TableCell>
                        {single.drinkDevice?.drink?.name
                          ? `${single.drinkDevice.drink.name} (${single.pressedDrinkID})`
                          : ''}
                      </TableCell>
                    )}
                    <TableCell align="right">
                      {single.totalAmount.toFixed('2')}
                      {single.totalAmount === 0 ? ' (ŽETON)' : ''}
                    </TableCell>
                    <TableCell>
                      {moment(single.timeOfBilling).format('HH:mm:ss (DD.MM.)')}
                    </TableCell>
                    <TableCell>
                      {!single.test
                        ? single.noOfReceipt > 0 &&
                          single.jir && (
                            <Link
                              href={`https://porezna.gov.hr/rn?jir=${
                                single.jir
                              }&datv=${moment(single.timeOfBilling).format(
                                'yyyyMMDD_HHmm'
                              )}&izn=${(single.totalAmount * 100).toFixed(0)}`}
                              target="_blank"
                              rel="noopener"
                            >
                              Provjeri
                            </Link>
                          )
                        : 'TEST'}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell
                component="th"
                scope="row"
                style={{ fontWeight: 'bold' }}
              >
                Ukupno:
              </TableCell>
              {showDrink && <TableCell></TableCell>}
              <TableCell align="right" style={{ fontWeight: 'bold' }}>
                {totalSum.toFixed('2')}
              </TableCell>
              <TableCell>Izračun u: {moment().format('HH:mm:ss')}</TableCell>
              <TableCell></TableCell>
            </TableRow>
            {totalSumTokens ? (
              <>
                <TableRow>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{ fontWeight: 'bold' }}
                  >
                    Ukupno u žetonima:
                  </TableCell>
                  {showDrink && <TableCell></TableCell>}
                  <TableCell align="right" style={{ fontWeight: 'bold' }}>
                    {totalSumTokens.toFixed('2')}
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{ fontWeight: 'bold' }}
                  >
                    Sve zajedno ukupno:
                  </TableCell>
                  {showDrink && <TableCell></TableCell>}
                  <TableCell align="right" style={{ fontWeight: 'bold' }}>
                    {(totalSum + totalSumTokens).toFixed('2')}
                  </TableCell>
                  <TableCell>
                    Izračun u: {moment().format('HH:mm:ss')}
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </>
            ) : null}
            <TableRow>
              <TablePagination
                style={{ flexShrink: 1 }}
                rowsPerPageOptions={[5, 10, 25, { label: 'Sve', value: -1 }]}
                colSpan={5}
                count={filteredData?.length ?? 0}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': 'redova po stranici' },
                  native: true,
                }}
                labelDisplayedRows={(info) =>
                  `${info.from}-${info.to} od ${info.count}`
                }
                labelRowsPerPage={'Red.: '}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
        <Button
          variant="outlined"
          color="primary"
          style={{
            textTransform: 'none',
            marginTop: '20px',
            marginBottom: '10px',
            width: '80px',
          }}
          onClick={() => {
            auth.signOut();
          }}
        >
          Odjava
        </Button>
      </Paper>
      <Modal open={isModalOpen} onClose={handleCloseModal}>
        <div style={modalStyle} className={classes.paper}>
          <h2 id="simple-modal-title">Stornirani račun:</h2>
          <div id="simple-modal-description">
            <p>
              BROJ:{' '}
              {cancelReceipt?.cancelReceipt?.noOfReceipt ??
                cancelReceipt?.canceledReceipt?.noOfReceipt ??
                'FALI PODATAK'}
            </p>
            <p>
              DATUM:{' '}
              {cancelReceipt?.cancelReceipt?.timeOfBilling ||
              cancelReceipt?.canceledReceipt?.timeOfBilling
                ? moment(
                    cancelReceipt?.cancelReceipt?.timeOfBilling ??
                      cancelReceipt?.canceledReceipt?.timeOfBilling
                  ).format('HH:mm:ss (DD.MM.yyyy)')
                : 'FALI PODATAK'}
            </p>
            <p>
              IZNOS:{' '}
              {cancelReceipt?.cancelReceipt?.totalAmount.toFixed(2) ??
                cancelReceipt?.canceledReceipt?.totalAmount.toFixed(2) ??
                'FALI PODATAK'}
            </p>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};
export default FiscalTable;
