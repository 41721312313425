import { Container, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import React, { useContext, useEffect, useState } from 'react';
import { Router } from '@reach/router';
import SignIn from './SignIn';
import FiscalTable from './FiscalTable';
import { UserContext } from '../providers/UserProvider';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '8px',
    height: '95vh',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#353434',
  },
}));

function App() {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const user = useContext(UserContext);

  useEffect(() => {
    setTimeout(() => setLoading(false), 3000);
  }, []);

  return (
    <Container maxWidth="md" className={classes.root}>
      <Box justifyContent="center">
        {user && user.user && user.userDocument ? (
          <FiscalTable />
        ) : (
          <Router>
            <SignIn path="/" />
          </Router>
        )}
      </Box>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
}

export default App;
