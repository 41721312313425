import React, { Component, createContext } from 'react';
import { auth, firestore } from '../services/firebase';

interface State {
  user: firebase.User | null;
  userDocument: any | undefined;
}

export const UserContext = createContext<State | undefined>(undefined);

class UserProvider extends Component<{}, State> {
  state: State = {
    user: null,
    userDocument: undefined,
  };

  generateUserDocument = async () => {
    const { user } = this.state;
    if (!user) {
      return;
    }
    const userRef = firestore.doc(`users/${user.uid}`);
    const snapshot = await userRef.get();
    if (!snapshot.exists) {
      const { email, displayName } = user;
      try {
        await userRef.set({
          displayName,
          email,
        });
      } catch (error) {
        console.error('Error creating user document', error);
      }
    }
    return this.getUserDocument(user.uid);
  };

  getUserDocument = async (uid: string) => {
    if (!uid) {
      return;
    }

    try {
      firestore.doc(`users/${uid}`).onSnapshot((userDocument) => {
        this.setState({
          ...this.state,
          userDocument: { uid, ...userDocument.data() },
        });
      });
    } catch (error) {
      console.error('Error fetching user', error);
    }
  };

  componentDidMount = async () => {
    auth.onAuthStateChanged((user) => {
      this.setState({ user }, async () => {
        await this.generateUserDocument();
      });
    });
    if (this.state.user && !this.state.userDocument) {
      await this.getUserDocument(this.state.user.uid);
    }
  };

  render() {
    return (
      <UserContext.Provider value={this.state}>
        {this.props.children}
      </UserContext.Provider>
    );
  }
}
export default UserProvider;
